import { DefaultTheme } from 'styled-components';

const colors = {
  black: {
    hover: 'var(--color-cta-blue, #5042f2)',
    regular: 'var(--color-black, #000000)',
  },
  footer: {
    background: 'var(--color-black, #000000)',
    hover: 'var(--color-cta-blue, #5042f2)',
    text: 'var(--color-white, #ffffff)',
  },
  links: {
    hover: 'var(--color-cta-blue, #5042f2)',
  },
  logoColor: 'var(--color-white, #ffffff)',
  primary: {
    hover: 'var(--color-cta-blue, #5042f2)',
    regular: 'var(--color-black, #000000)',
  },
  quaternary: {
    hover: '',
    light: '',
    regular: '',
  },
  secondary: {
    hover: '',
    regular: '',
  },
  tertiary: {
    hover: '',
    light: '',
    regular: '',
  },
  white: {
    regular: 'var(--color-white, #ffffff)',
  },
};

const spacing = {
  block: '',
  large: 'var(--spacing-large, 6rem)',
  medium: 'var(--spacing-medium, 3rem)',
  menuHeight: 'var(--spacing-menu-height, 3.5rem)',
  regular: 'var(--spacing-regular, 1.5rem)',
  small: 'var(--spacing-small, 1rem)',
  xl: 'var(--spacing-xl, 12rem)',
  xsmall: 'var(--spacing-xsmall, 0.5rem)',
  xxsmall: 'var(--spacing-xxsmall, 0.25rem)',
};

const fonts = {
  families: {
    regular: 'var(--font-family)',
  },
  weights: {
    bold: 900,
    regular: 300,
  },
};

export const defaultTheme: DefaultTheme = {
  colors: colors,
  fonts: fonts,
  spacing: spacing,
};
