import { useEffect } from 'react';

import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import localFont from 'next/font/local';
import Head from 'next/head';

import { defaultTheme } from '@hultafors/hultaforsgroup/helpers';
import { PageProps } from '@hultafors/hultaforsgroup/types';
import '@hultafors/shared/style/main.scss';
import './global.scss';
import './layout.scss';

export { reportWebVitals } from '@hultafors/shared/helpers';

const AppProvider = dynamic(() =>
  import('@hultafors/hultaforsgroup/context').then((mod) => mod.AppProvider),
);

const Footer = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then((mod) => mod.Footer),
);

const Nav = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then((mod) => mod.Nav),
);

const SharedThemeProvider = dynamic(() =>
  import('@hultafors/shared/context').then((mod) => mod.SharedThemeProvider),
);

const franklinItcPro = localFont({
  src: [
    {
      path: '../fonts/FranklinITCPro-Light.otf',
      style: 'normal',
      weight: '300',
    },
    {
      path: '../fonts/FranklinITCPro-Medium.otf',
      style: 'bold',
      weight: '500',
    },
  ],
  variable: '--font-franklin-itc-pro',
});

const CustomApp = ({ Component, pageProps, router }: AppProps<PageProps>) => {
  const { globalContent, settings, menu, featureToggle, footer, ...restProps } =
    pageProps;
  const isCampaignPage = router.asPath.startsWith('/campaign');

  const onRouteChangeComplete = (page: string) => {
    sendGTMEvent({ event: 'pageview', page });
  };

  useEffect(() => {
    if (router.events) {
      onRouteChangeComplete('/');
      router.events.on('routeChangeComplete', onRouteChangeComplete);
    }
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-franklin-itc-pro: ${franklinItcPro.style.fontFamily};
            --font-family-hero: ${franklinItcPro.style.fontFamily};
            --font-family-regular: ${franklinItcPro.style.fontFamily};
            --font-family: ${franklinItcPro.style.fontFamily};
          }
        `}
      </style>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <SharedThemeProvider theme={defaultTheme}>
        <AppProvider
          value={{ featureToggle, footer, globalContent, menu, settings }}
        >
          {!isCampaignPage && <Nav />}
          <Component {...pageProps} />
          <GoogleTagManager gtmId="GTM-PL8PKQ" />
          {!isCampaignPage && <Footer />}
          <div id="portal"></div>
        </AppProvider>
      </SharedThemeProvider>
    </>
  );
};

export default CustomApp;
